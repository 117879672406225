#loader {
  position: absolute;
  bottom: 50vh;
  left: 50%;
}
/* .page-container {
  display: flex;
} */
/* table{
	width: max-content !important;
} */
.react-responsive-modal-root {
  z-index: 99999999999 !important;
}
.container {
  background-color: var(--primary-bg-color);
  padding: 25px 50px 0px 50px;
  margin-left: 250px;
}
@media screen and (max-width: 769px) {
  .container {
    margin-left: 50px;
    padding: 20px 20px 0 10px;
  }
  .Heading h2 {
    font-size: var(--desktop-paragraph-font);
  }
}
body {
  -webkit-tap-highlight-color: transparent;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
}
input:focus,
textarea:focus,
select:focus {
  outline: none;
  cursor: pointer;
}
.input-box {
  display: flex;
  gap: 10px;
  align-items: center;
  border: 1px solid var(--primary-border-color);
  padding: 10px;
  border-radius: 8px;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
input[type="date"],
input[type="search"],
input[type="url"],
select,
textarea {
  border: none;
  width: 100%;
  font-size: 17px;
  cursor: pointer;
  background-color: transparent;
}
input[type="submit"] {
  border: none;
  font-size: 15px;
}
.row-link {
  cursor: pointer;
  transition: all 0.1s ease;
}
.row-link:hover {
  background-color: var(--primary-bg-color);
}
/* ======= Login page ======= */
/* ======= Login page ======= */
#login-container {
  display: flex;
  width: 100vw;
  height: 100vh;
}
#login-container .login-left {
  flex: 0.5;
  background-color: var(--primary-color);
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 100px;
  width: 50%;
}
.login-left h2 {
  text-align: center;
  font-size: var(--secondary-desktop-heading-font);
}
.login-left .logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.login-left .logo img {
  width: 150px;
}
#login-container .login-right {
  flex: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-heading {
  color: var(--primary-color);
  font-size: var(--secondary-desktop-heading-font);
  font-weight: 700;
}
.login-heading .underline {
  border-bottom: 2px solid var(--primary-color);
}
#login-container form {
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  width: 80%;
}
.login-form {
  display: flex;
  flex-direction: column;
  /* gap: 20px; */
  width: 70%;
}

.login-form .forgot {
  display: flex;
  justify-content: space-between;
  color: var(--primary-color);
  /* font-weight: 500; */
  padding-top: 5px;
}

.login-btn button {
  background-color: var(--primary-color);
  padding: 5px 35px 10px 35px;
  border-radius: 5px;
  font-size: var(--tertiary-tablet-heading-font);

  color: #ffffff;
}
@media screen and (max-width: 768px) {
  #login-container .login-left {
    display: none;
  }
  #login-container .login-right {
    flex: none;
    width: 100%;
  }
  #login-container form {
    width: 100%;
  }
  .login-form .forgot span {
    font-size: 14px;
  }
}

/* ======= SIDE BAR ====== */
/* ======= SIDE BAR ====== */
#side-bar {
  height: 100vh;
  position: fixed;
  /* left: 0; */
  /* top: 0; */
  /* color: ; */
}
#side-bar .logo-desc {
  padding: 20px 0 10px 0;
  text-align: center;
}
#side-bar .logo-desc img {
  width: 90px;
}
.ps-sidebar-container {
  padding: 0 10px !important;
}
.ps-active {
  background: var(--tertiary-color);
  /* padding: 10px; */
  border-radius: 8px;
}
.ps-disabled {
  color: rgb(199, 199, 199) !important;
}
.css-1aun9na > .ps-menu-button {
  background: none !important;
}
@media screen and (max-width: 769px) {
  .ps-sidebar-container {
    padding: 0 5px !important;
  }
  #side-bar .logo-desc h4 {
    display: none;
  }
  #side-bar .logo-desc img {
    width: 40px;
  }
  .ps-menu-button {
    padding: 0px !important;
  }
  .css-1wvake5 {
    width: 50px !important;
    min-width: 50px !important;
  }
  .menu-item {
    margin: 0 8px !important;
  }
  .menu-icon {
    font-size: 22px !important;
  }
}
.menu-item {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 0 15px;
}
.menu-icon {
  font-size: 25px;
}
.logout {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
}
.logout .buttons {
  display: flex;
  gap: 10px;
}
/* ======= TOP BAR ====== */
header {
  position: sticky;
  top: 0;
  display: flex;
  background-color: #ffffff;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--primary-border-color);
  border-left: none;
  padding: 10px 100px;
  margin-left: 250px;
  z-index: 999999;
}
header input[type="search"] {
  all: unset;
  border: 1px solid var(--primary-border-color);
  padding: 10px 20px;
  width: 100%;
  border-radius: 8px;
}
header .header-right {
  display: flex;
  align-items: center;
  gap: 40px;
}
.header-right .bell-icon {
  font-size: 30px;
  background: rgba(93, 82, 212, 0.22);
  color: var(--primary-color);
  padding: 10px;
  border-radius: 15px;
  cursor: pointer;
}
.header-right .profile {
  display: flex;
  gap: 20px;
}
.header-right .profile img {
  width: 50px;
}
@media screen and (max-width: 768px) {
  header {
    margin-left: 50px;
    padding: 10px;
  }
  header .header-right {
    gap: 10px;
  }
  .header-right .profile img {
    display: none;
  }
  .header-right .bell-icon {
    font-size: 15px;
    border-radius: 8px;
  }
  header input[type="search"] {
    width: 65%;
  }
  .header-right .profile p {
    display: none;
  }
  .mobile-student-header h2 {
    font-size: 12px;
  }
  .mobile-student-header .header-right .profile p {
    font-size: 12px;
    display: block;
  }
}
/* ====== Dashboard Section ====== */
/* ====== Dashboard Section ====== */
.stats-container {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  gap: 50px;
}
.stats-container a {
  width: calc(100% / 3);
}
.stats-container .stats-card h4 {
  font-size: var(--secondary-desktop-heading-font);
}
.stats-container .stats-card p {
  font-size: var(--desktop-paragraph-font);
}
.stats-container .stats-card {
  padding: 20px;

  border-radius: 8px;
  /* background-position: right; */
}
.stats-container .card1 {
  background: url("/public/Images/stats-1.svg") no-repeat right;
  background-color: #64dcb1;
  background-blend-mode: soft-light;
}
.stats-container .card2 {
  background: url("/public/Images/stats-2.svg") no-repeat right;
  background-color: #82ced3;
}
.stats-container .card3 {
  background-position: right;
  background: url("/public/Images/stats-3.svg") no-repeat right;
  background-size: 150px;
  background-color: #edc0c0;
}
#dashboard {
  display: flex;
  justify-content: space-between;
  gap: 50px;
}
#dashboard .dashboard-left {
  flex: 0.7;
  margin: 20px 0;
}
#dashboard .dashboard-right {
  flex: 0.3;
  margin: 20px 0;
}
.see-all {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: var(--primary-color);
  margin: 5px;
  font-weight: 700;
  gap: 5px;
  cursor: pointer;
}
.notice-section {
  background-color: #ffffff;
  padding: 10px 0 0 0;
  margin: 25px 0;
  border-radius: 8px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
}
.notice-section ul {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-left: 30px;
}
.notice-section ul li {
  list-style: none;
}
.notice-section .item .notes-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;

  /* font-size: 25px; */
  background-color: #0038a3;
  color: #ffffff;
  border-radius: 50%;
  padding: 7px;
}
.notice-section .item .notice {
  display: flex;
  align-items: center;
  gap: 10px;
}
.item .notes-icon .icon {
  color: #ffffff;
}
.notice-section .item p {
  font-size: 12px;
}
.notice-section button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  padding: 10px 0;
  font-weight: 600;
  background-color: var(--secondary-color);
}
@media screen and (max-width: 768px) {
  #dashboard {
    flex-direction: column;
    gap: 0px;
  }
  #dashboard .dashboard-left {
    margin: 0;
  }
  .stats-container {
    gap: 7px;
  }
  .stats-container .stats-card {
    padding: 10px;
  }
  .stats-container .stats-card h4 {
    font-size: var(--tertiary-tablet-heading-font);
  }
  .stats-container .stats-card p {
    font-size: 12px;
  }
  .notice-section ul {
    padding-left: 12px;
  }
  .AlumniListTable .see-all {
    font-size: 13px;
  }
}
/* ====== Notice Section ====== */
/* ====== Notice Section ====== */
.notice-container .notice-form {
  border-radius: 10px;
  border-bottom: 5px solid #0038a3;

  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
  padding: 50px;
  background-color: #ffffff;
}
.notice-container .notice-heading h2 {
  font-size: var(--secondary-desktop-heading-font);
}
.notice-container .notice-form h3 {
  font-size: var(--tertiary-desktop-heading-font);
}
.underline {
  border-bottom: 3px solid var(--primary-color);
}
.notice-form form {
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
}
.notice-form form .form {
  display: flex;
  gap: 20px;
  width: 100%;
}
.fee-details-container {
  display: flex;
  gap: 30px;
  justify-content: space-around;
}
/* .feeform{
	height: max-content;
} */
.feeform form {
  gap: 25px;
}
.feeform .form-left {
  flex: 0.5;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.feeform form .form .form-right {
  flex: 0.5;
}
.feeform .form-left .course-sem {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.feeform .form-left .course-sem .input-box {
  width: 100%;
}
.notice-form form .form .form-right {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 60%;
}
.submit-btn {
  cursor: pointer;
  background-color: var(--primary-color);
  padding: 10px 30px;
  border-radius: 7px;
  color: #ffffff;
}
@media screen and (max-width: 768px) {
  .submit-btn {
    padding: 7px 15px;
    font-size: 0.8rem;
  }
  .notice-container .notice-form {
    padding: 20px;
  }
  .notice-form form .form .form-right {
    width: 90%;
  }
  .feeform form .form .form-right {
    width: 100%;
  }
  .notice-form form .form {
    flex-direction: column;
  }
  .fee-details img {
    display: none;
  }
  .fee-details-container {
    display: flex;
    gap: 20px;
    flex-direction: column-reverse;
  }
}

/* ====== Recent Notice Section ===== */
.RecentNotice-container {
  margin: 80px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.Recent-notice-search {
  display: flex;
  justify-content: space-between;
}
.Recent-notice-search .search-filter {
  display: flex;
  gap: 20px;
  /* width: 70%; */
}
.Recent-notice-search input[type="search"],
.Recent-notice-search input[type="text"] {
  width: auto;
  border-radius: 30px;
  padding: 10px 20px;
  border: 1px solid var(--primary-border-color);
}
.Recent-notice-search select {
  width: auto;
  border-radius: 30px;
  padding: 10px 20px;
  border: 1px solid var(--primary-border-color);
}
.Recent-notice {
  padding: 20px 0;
}
.Recent-notice-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: space-between;
}
.Recent-notice-cards .notice-card {
  display: flex;
  flex-direction: column;
  gap: 15px;
  border-radius: 10px;
  padding: 15px;
  border-left: 5px solid var(--primary-color);
  background-color: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  width: calc(100% / 2.3);
}
.notice-card .card-title h3 {
  font-size: var(--desktop-paragraph-font);
  color: var(--primary-text-color);
}
.notice-card .card-desc {
  display: flex;
  gap: 20px;
  align-items: center;
}
.notice-card .card-desc .topic,
.date {
  display: flex;
  align-items: center;
}
.notice-card .card-action {
  display: flex;
  justify-content: space-between;
}
.notice-card .card-action button {
  padding: 5px 15px;
  background-color: var(--primary-border-color);
  border-radius: 20px;
}

.notice-card .Pending {
  padding: 5px 15px 8px 15px;
  border-radius: 20px;
  width: max-content;
  cursor: pointer;
  background-color: #f88;
}
.notice-card .Inreview {
  padding: 5px 15px 8px 15px;
  border-radius: 20px;
  width: max-content;
  cursor: pointer;
  background-color: #ffdc81;
}
.notice-card .Approved {
  padding: 5px 15px 8px 15px;
  border-radius: 20px;
  width: max-content;
  cursor: pointer;
  background-color: #79e66f;
}
.notice-card .title h3 {
  overflow-wrap: anywhere;
}
.notice-card .card-desc p {
  overflow-wrap: anywhere;
}
.edit-btn {
  background-color: #bdd5ff !important;
}
.delete-btn {
  background-color: #e94444 !important;
  color: #ffffff !important;
}
.un-publish-btn {
  background-color: #60a9ff !important;
  color: #ffffff !important;
}
.notice-card .icon {
  padding-right: 5px;
  color: var(--secondary-text-color);
}
@media screen and (max-width: 768px) {
  .RecentNotice-container {
    margin-top: 30px;
  }
  .Recent-notice-search {
    flex-direction: column;
    gap: 20px;
  }
  .Recent-notice-search .search-filter {
    flex-wrap: wrap;
    gap: 10px;
    justify-content: flex-end;
  }
  .Recent-notice-cards {
    width: 100%;
  }
  .Recent-notice-cards .notice-card {
    width: 100%;
  }
  .notice-card .card-action {
    gap: 2px;
    font-size: var(--phone-paragraph-font);
  }
  .notice-card .card-desc {
    gap: 10px;
    font-size: var(--phone-paragraph-font);
  }
}

/* ======= ALUMNI LIST ========= */
/* ======= ALUMNI LIST ========= */
.AlumniListTable {
  margin: 50px 0 20px 0;
  overflow-x: scroll;
}
.AlumniListTable table {
  font-size: var(--phone-paragraph-font);
  width: 100%;
  background-color: #ffffff;
  text-align: center;
  border-collapse: collapse;
  padding: 0 10px;
  border: 1px solid var(--primary-border-color);
}
.AlumniListTable .Table-Header {
  border: 1px solid var(--primary-border-color);
}
.AlumniListTable table th {
  padding: 10px;
  background-color: var(--secondary-color);
}
.AlumniListTable table tr {
  border-bottom: 1px solid var(--primary-border-color);
}
.AlumniListTable table tr a {
  font-weight: 500;
  /* color:#707070; */
}
.AlumniListTable table td {
  padding: 8px;
  /* border-bottom: 1px solid var(--primary-border-color); */
}
.AlumniListTable table .msg {
  width: 400px;
  text-align: left;
}
.AlumniListTable table .desc {
  display: block;
  width: 200px;
  text-align: center;
}
.AlumniListTable button {
  padding: 5px 15px;
  background-color: var(--primary-border-color);
  border-radius: 20px;
  margin: 5px 5px 0 0;
  font-size: 12px;
}

.AlumniListTable table img {
  width: 45px;
  height: 45px;
}
[aria-label="Pagination"] {
  display: flex;
  /* border: 1px solid black; */
  /* width: min-content; */
  justify-content: flex-end;
  gap: 5px;
}
[aria-label="Pagination"] li {
  list-style: none;
}
[aria-label="Pagination"] a:hover {
  background-color: var(--tertiary-color);
}
[aria-label="Pagination"] .selected a {
  background-color: var(--tertiary-color);
}
[aria-label="Pagination"] li a {
  list-style: none;
  padding: 5px 10px;
  transition: all 0.1s ease;
  border-radius: 5px;
  border: 1px solid var(--primary-border-color);
}

[aria-label="Pagination"] .previous a,
[aria-label="Pagination"] .next a {
  background-color: var(--tertiary-color);
  border: none;
}
.res-status {
  color: #e94444;
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  .AlumniListTable {
    margin: 20px 0;
  }
  .AlumniListTable table {
    width: max-content;
    font-size: var(--phone-paragraph-font);
  }
  .Recent-notice-search select {
    font-size: var(--phone-paragraph-font);
  }
}
/* REACT CALANDER  */
.react-calendar {
  border: none !important;
  padding: 5px 10px;
  border-radius: 9px;
  background: #fff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
  width: auto !important;
}
abbr[title] {
  text-decoration: none;
}
.react-calendar__navigation button {
  text-align: center !important;
}
.react-calendar__tile--active {
  color: var(--primary-color) !important;
  color: #ffffff !important;
  border-radius: 50px;
}
.react-calendar__tile--now {
  background-color: rgb(255, 153, 153) !important;
  color: #ffffff !important;
}

/* Account Create / Update Sem  */
.react-responsive-modal-modal {
  border-radius: 10px;
}
.account form,
.update-sem {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px 0;
}

/*======= STUDENT PANEL =========*/
/*======= STUDENT PANEL =========*/
/* Navbar  */
#stu-nav ul li {
  list-style: none;
  font-size: var(--desktop-paragraph-font);
  /* background-color: var(--primary-color); */
  cursor: pointer;
  color: #ffffff;
  width: max-content;

  /* transform: skewX(-20deg); */
  /* padding: 8px 6px; */
  padding: 5px 15px 8px 15px;
  border-radius: 10px;
  background: #0e6dab;
  box-shadow: 0px 2px 10px 0px rgba(14, 109, 171, 0.15);
}
#stu-nav ul {
  display: flex;
  gap: 10px;
  padding: 0;
}
#stu-nav .active {
  all: unset;
  /* font-weight: 700; */
  color: var(--primary-color);
}
@media screen and (max-width: 768px) {
  #stu-nav ul li {
    border-radius: 15px;
    font-size: 12px;
    padding: 5px 15px 7px 15px;
  }
  #stu-nav ul {
    overflow-x: scroll;
  }
}
.action-right {
  display: flex;
  flex: 0.5;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
.action-right h2 {
  font-size: 30px;
}
.action-right .actions ul {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 0;
}
.action-right .actions ul li {
  cursor: pointer;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
  padding: 13px 40px 13px 15px;

  /* background-color: red; */
}
.action-right .actions ul li:hover {
  transition: all 0.3s ease-in-out;
  background: #cddfff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
}
@media screen and (max-width: 768px) {
  .action-right {
    flex: auto;
  }
}

.student-panel {
  margin: 30px 100px;
}
.complain-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.complain-form form {
  width: 100%;
}
.complain-form form .form .form-right {
  width: 100%;
  gap: 10px;
}
.resources-form {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .resources-form {
    flex-direction: column;
  }
  .complain-form form {
    gap: 30px;
  }
  .complain-form img {
    display: none;
  }
  .student-panel {
    margin: 20px;
  }
  .notice-container .notice-heading h2 {
    font-size: var(--tertiary-desktop-heading-font);
  }
}

/*====== SUTDENT DETAILS FORM */
.AlumniContainer {
  margin: 50px 150px;
  border-bottom: 8px solid var(--primary-color);
  border-radius: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin: 0 25%;
  padding: 30px;
  background-color: #ffffff;
}
.AlumniForm {
  display: flex;
  flex-direction: column;
  color: var(--fade-text-color);
}
.AlumniForm form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.AlumniForm .TopRow {
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  align-items: center;
}
.AlumniForm .TopRow .nameEmail {
  display: flex;
  flex-direction: column;

  gap: 30px;
}
#file {
  display: none;
}
.filebox {
  display: inline-flex;
  flex: 0.1;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  width: 100px;
  height: 120px;
  border-radius: 10px;
  /* border: 1px dotted var(--fade-text-color); */
}
.nameEmail {
  flex: 0.9;
}
.AlumniForm input[type="text"],
.AlumniForm input[type="email"],
.AlumniForm input[type="date"],
.AlumniForm input[type="number"] {
  width: -webkit-fill-available;
}

.AlumniForm .Row {
  display: flex;
  margin: 10px 0;
  /* align-items: center; */
  justify-content: space-between;
  gap: 50px;
}
.AlumniForm .Row .RowLeft {
  display: flex;
  gap: 10px;
  flex: 0.5;
}
.AlumniForm .Row .RowRight,
.ResearchForm .Row .RowRight {
  display: flex;
  flex: 0.5;
}
.AlumniForm #file-img {
  width: 140px;
  height: 120px;

  object-fit: cover;
  position: absolute;
}
.stu-form-container {
  margin: 0px 50px;
}
.stu-form-container input[type="submit"] {
  width: fit-content;
  align-self: center;
}
.stu-form-container .row {
  width: 100%;
}
.stu-form-container form {
  gap: 20px !important;
}
.stu-form-container form .nameEmail {
  gap: 20px !important;
}
.stu-form-container .AlumniForm .BottomRow {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.stu-form-container .AlumniForm .RowLeft {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.stu-form-container .AlumniForm .RowRight {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.stu-form-container .AlumniForm .course-sem-session {
  display: flex;

  gap: 10px;
}
.stu-form input[type="text"],
.stu-form input[type="email"],
.stu-form input[type="date"],
.stu-form input[type="number"] {
  width: 100%;
}
/*===== STUDENT DETIALS START =====*/

.stu-details-container {
  display: flex;
  flex-direction: column;
}
.stu-details-container .top {
  display: flex;
  gap: 50px;
  margin: 20px 0;
}
.stu-details-container .top h2 {
  font-size: var(--tertiary-tablet-heading-font);
  width: -webkit-fill-available;
  text-align: center;
  padding: 10px 20px;
  background-color: var(--secondary-color);
  border-radius: 10px 10px 0 0;
}
.stu-details-container .top .profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.stu-details-container .top .profile img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 10px 0;
}
.stu-details-container .profile-desc {
  margin: 20px 0;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.stu-details-container .top .left {
  flex: 0.4;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
}
.stu-details-container .top .right {
  flex: 0.6;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
}
.stu-details-container .top p {
  font-size: var(--desktop-paragraph-font);
  color: var(--primary-text-color);
}
.stu-details-container .top h4 {
  font-size: var(--tertiary-tablet-heading-font);
}
.stu-details-container .top label {
  color: #000000;
  font-weight: 500;
}
.stu-details-container .top span {
  color: var(--fade-text-color);
}
/* .apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center, .apexcharts-legend.apx-legend-position-top.apexcharts-align-center{
	justify-content: flex-start !important;
}
.apexcharts-canvas{
	text-align: center !important;
} */
@media screen and (max-width: 768px) {
  .stu-details-container .top {
    gap: 50px;
    flex-direction: column;
    margin: 20px 0;
  }
  .stu-details-container .top h2 {
    font-size: var(--secondary-phone-heading-font);
  }
  .stu-details-container .top label {
    font-size: var(--phone-paragraph-font);
  }
  .stu-details-container .top h4 {
    font-size: var(--tertiary-phone-heading-font);
  }
  .apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center,
  .apexcharts-legend.apx-legend-position-top.apexcharts-align-center {
    justify-content: flex-start !important;
  }
}
/*===== STUDENT DETIALS END =====*/
@media screen and (max-width: 769px) {
  .AlumniHeader > img {
    width: 100%;
    height: 20vh;
  }

  .AlumniContainer {
    margin: 0;
  }
  .stu-form-container {
    margin: 20px 0 !important;
  }
  .stu-form-container .TopRow {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px !important;
  }
  .stu-form-container .TopRow .nameEmail {
    width: auto !important;
  }
  .AlumniForm form,
  .ResearchForm form {
    gap: 0;
  }
  .AlumniForm .TopRow {
    display: flex;
    gap: 0px;
    margin-bottom: 10px;
  }
  .AlumniForm .TopRow .nameEmail {
    width: 150px;
    gap: 20px;
  }

  .AlumniForm .Row {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    gap: 20px;
  }
  .filebox {
    width: 80px;
    height: 100px;
  }

  .AlumniForm #file-img {
    width: 120px;
    height: 100px;
  }
  .stu-form-container .AlumniForm .BottomRow {
    display: flex;
    flex-direction: column;
  }
}
/*====== fee Txn ===== */
.feeTxn {
  margin: 40px;
}
.feeTxn table {
  font-size: var(--phone-paragraph-font);
  border-collapse: collapse;
  background-color: #ffffff;
  padding: 20px;
  width: 100%;
  text-align: center;
  border-radius: 10px;

  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
}
.feeTxn table thead tr {
  border-bottom: 1px solid #666;
}
.feeTxn table tr {
  border-bottom: 1px solid var(--primary-border-color);
}
.feeTxn table thead th {
  padding: 10px;
}

.feeTxn table td {
  padding: 8px;
}

.feeTxn .ruppee {
  color: rgba(255, 0, 0, 0.9);
}
.feeTxn table td {
  font-size: var(--desktop-paragraph-font);
}
@media screen and (max-width: 768px) {
  .feeTxn {
    margin: 30px 0;
    overflow-x: scroll;
  }
  .feeTxn table td {
    font-size: 12px;
    /* font-size: var(--phone-paragraph-font); */
  }
}
