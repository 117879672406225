body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
   background-color: var(--primary-bg-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
img{
  pointer-events: none;
  object-fit: cover;
}
h1,h2,h3,h4,h5,h6,p{
  margin:0;
  padding:0;
}
a,button{
  text-decoration: none;
  all:unset;
  cursor: pointer;
}

:root{
  
  --primary-color:#183497;
  --secondary-color:#BDD5FF;
  --tertiary-color:#b3c7ec;

  --primary-text-color:#484848;
  --secondary-text-color:#464255;

  --primary-bg-color:#F5F5F5;
  --primary-border-color:#e2e2e2;
  

/*Font size desktop*/
--primary-desktop-heading-font:2.5rem;
--secondary-desktop-heading-font:2rem;
--tertiary-desktop-heading-font:1.5rem;

--desktop-paragraph-font:1.1rem;


/*Font size tablet*/
--primary-tablet-heading-font:2rem;
--secondary-tablet-heading-font:1.5rem;
--tertiary-tablet-heading-font:1.3rem;

--tablet-paragraph-font:1rem;


/*Font size phone*/
--primary-phone-heading-font:1.7rem;
--secondary-phone-heading-font:1.3rem;
--tertiary-phone-heading-font:1rem;


--phone-paragraph-font:0.9rem;}
